import * as React from 'react';
import { useRecoilState } from 'recoil';
import { SessionOverview } from '.';
import { ConfigState } from '../states/ConfigState';

export interface IScheduleProps {}

export const Schedule: React.FunctionComponent<IScheduleProps> = (props: IScheduleProps) => {
  const [ config ] = useRecoilState(ConfigState);

  return (
    config && config.sessions && (
      <section className="container items-center m-auto mt-8" role="list">
        <div className="container px-5 pb-8 mx-auto">
          <div className="flex flex-col text-center w-full">
            <h2 id="schedule" className="text-xs text-cadetblue-700 tracking-widest font-medium title-font mb-1">Schedule</h2>
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-cadetblue-900">The agenda for this evening</h1>
          </div>
        </div>
        <div className="flex flex-wrap">
          <SessionOverview sessions={config.sessions} />
        </div>
      </section>
    )
  );
};
