import * as React from 'react';
import { useRecoilState } from 'recoil';
import { Channel as CrntChannel } from '../models';
import { ConfigState } from '../states/ConfigState';
import { Channel } from './Channel';
import { parse, format, differenceInDays } from 'date-fns';
export interface ILiveSessionProps {
  channel: CrntChannel | null;
}

export const LiveSession: React.FunctionComponent<ILiveSessionProps> = (props: ILiveSessionProps) => {
  const [ config ] = useRecoilState(ConfigState);
  const { channel } = props;

  let crntChannel = null;
  if (channel) {
    crntChannel = channel;
  } else {
    if (config && config.channels[0]) {
      crntChannel = config.channels[0];
    } else {
      return null;
    }
  }

  if (!config) {
    return null;
  }

  const now = new Date();
  const channelDate = new Date(crntChannel.startsAt);
  if (differenceInDays(now, channelDate) >= 0) {
    return (
      <section className="container flex flex-wrap md:flex-no-wrap mx-auto">
        <Channel key={crntChannel.title} info={crntChannel} />
      </section>
    );
  } else {
    return (
      <section className="channel__container container flex justify-center items-center mx-auto bg-gray-200 rounded-md">
        <h2 className="text-3xl text-center">Welcome to Virtual DIWUG, our new location for MeetUp evening events. <br />{ config ? `Our next event is planned on: ${format(parse(config.eventDate, "yyyy-MM-dd", new Date()), "dd/MM/yyyy")}` : ""}</h2>
      </section>
    );
  }
};
