import * as React from 'react';
import { useRecoilState } from 'recoil';
import { ConfigState } from '../states/ConfigState';

export interface ISponsorsProps { }

export const Sponsors: React.FunctionComponent<ISponsorsProps> = (props: ISponsorsProps) => {
  const [config] = useRecoilState(ConfigState);

  if (!config || !config.sponsors || config.sponsors.length <= 0) {
    return null;
  }

  const eveningSponsor = config.sponsors && config.sponsors.filter(s => s.frontPage)![0];

  if (!eveningSponsor) {
    return null;
  }

  return (
    <section className="container flex flex-col items-center m-auto leading-none mt-8 mb-8" role="list">
      <div className="w-full px-5 mx-auto">
        <div className="flex flex-col text-center w-full">
          <h2 id="speakers" className="text-xs text-cadetblue-700 tracking-widest font-medium title-font mb-1">Our evening sponsor</h2>
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-cadetblue-900">Thanks to {eveningSponsor.title}</h1>
        </div>
      </div>

      <div className="w-1/2 px-5 mx-auto text-center">
        <a key={eveningSponsor.title.replace(/ /g, '_')} className="sponsor block mb-2 md:mb-0" href={eveningSponsor.url} target="_blank" title={eveningSponsor.title} rel="noopener noreferrer">
          <img className="text-center mx-auto" src={eveningSponsor.logo} alt={eveningSponsor.title} />
        </a>

        <p>{eveningSponsor.description}</p>
      </div>
    </section>
  );
};
