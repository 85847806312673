import * as React from 'react';
import { useRecoilState } from 'recoil';
import { ConfigState } from '../states/ConfigState';

export interface ISponsorBannerProps {}

export const SponsorBanner: React.FunctionComponent<ISponsorBannerProps> = (props: ISponsorBannerProps) => {
  const [ config ] = useRecoilState(ConfigState);

  if (!config || !config.sponsors || config.sponsors.length <= 0) {
    return null;
  }

  const eveningSponsor = config.sponsors && config.sponsors.filter(s => s.frontPage)![0];

  if (!eveningSponsor || !eveningSponsor.banner) {
        return null;
  }

  return (
    <section className="container flex flex-col items-center m-auto leading-none" role="list">
      <div className="w-full mx-auto">
        <a key={eveningSponsor.title.replace(/ /g, '_')} className="sponsor block mb-2 md:mb-0" href={eveningSponsor.url} target="_blank" title={eveningSponsor.title} rel="noopener noreferrer">
          <img src={eveningSponsor.banner} alt={eveningSponsor.title} />
        </a>
      </div>
    </section>
  );
};
